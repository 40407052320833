import { getCurrentUser } from "@/apis/user";
import { Loading } from "@/components/module/Loading";
import { RedirectToSignIn } from "@/components/module/RedirectToSignIn";
import { isAxiosError } from "@/lib/http";
import { setLocalStorage } from "@/lib/utils";
import { FullUser } from "@/types/users";
import { useAuth0 } from "@auth0/auth0-react";
import { captureException } from "@sentry/react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import _ from "underscore";

type UserProviderProps = {
  children: React.ReactNode;
};

type UserProviderState = {
  login: () => void;
  logout: () => void;
  user: FullUser | null;
  error: Error | null;
  isAuthenticated: boolean;
  auth0Loading: boolean;
  getAccessToken: () => Promise<string | null>;
};

const initialState: UserProviderState = {
  login: () => null,
  logout: () => null,
  user: null,
  error: null,
  isAuthenticated: false,
  auth0Loading: true,
  getAccessToken: () => Promise.resolve(""),
};

const UserProviderContext = createContext<UserProviderState>(initialState);

export const UserProvider = ({ children }: UserProviderProps) => {
  const {
    isLoading,
    user: auth0User,
    error,
    loginWithRedirect,
    logout: authLogout,
    isAuthenticated,
    getAccessTokenSilently,
  } = useAuth0();
  const [user, setUser] = useState<UserProviderState["user"]>(
    initialState.user
  );

  const login = async () => {
    if (window.location.pathname !== "/") {
      setLocalStorage("returnTo", window.location.pathname);
    }
    await loginWithRedirect({
      appState: {
        returnTo: window.location.pathname,
      },
    });
  };

  const logout = async () => {
    await authLogout({ logoutParams: { returnTo: window.location.origin } });
  };

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      return token;
    } catch (error) {
      console.error(error);
      await login();
      return null;
    }
  };

  const getUserData = async () => {
    if (isLoading || !auth0User || !isAuthenticated) {
      return;
    }
    try {
      const token = await getAccessTokenSilently();

      const current_user = await getCurrentUser(token, () => void login);

      if (current_user && !_.isEqual(user, { ...auth0User, ...current_user })) {
        setUser({
          ...auth0User,
          ...current_user,
        });
      }
    } catch (e) {
      if (isAxiosError(e)) {
        if (e.response?.status === 401) {
          login().catch(console.error);
        }
      }
      throw e;
    }
  };

  useEffect(() => {
    getUserData().catch(console.error);

    const interval = setInterval(() => {
      getUserData().catch(console.error);
    }, 15 * 1000);

    return () => clearInterval(interval);
  }, [isAuthenticated]);

  const value = useMemo(
    () => ({
      user: user ?? null,
      error: error ?? null,
      login,
      logout,
      isAuthenticated,
      auth0Loading: isLoading,
      getAccessToken,
    }),
    [user, error, isAuthenticated, isLoading]
  );

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated || !auth0User) {
    login().catch(console.error);
    return <RedirectToSignIn />;
  }

  if (error) {
    console.error(error);
    captureException(error);
  }

  return (
    <UserProviderContext.Provider value={value}>
      {children}
    </UserProviderContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserProviderContext);

  if (context === undefined)
    throw new Error("useUser must be used within a UserProvider");

  return context;
};
