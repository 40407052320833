import axios, { isAxiosError as _isAxiosError } from "axios";
import { EventSourceParserStream } from "eventsource-parser/stream";
import { toast } from "react-toastify";

const baseURL = import.meta.env.VITE_PUBLIC_API_BASE_URL;

export const httpClient = axios.create({
  baseURL,
});

export const isAxiosError = _isAxiosError;

export const handleAxiosError = (error: unknown, login: () => void): void => {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 401) {
      login();
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const message =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      error.response?.data.detail &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      typeof error.response?.data.detail === "string"
        ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          error.response?.data.detail
        : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          error.response?.data.detail[0].msg
          ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            error.response?.data.detail[0].msg
          : "Something went wrong";
    console.log(message);
    toast.error(message as string);
  } else {
    // This is not an Axios error, so re-throw it
    throw error;
  }
};

export const httpClientStream = async (
  url: string,
  options: RequestInit = {},
  onDataChunk: (chunk: string) => void,
  onComplete?: () => void,
  onError?: (error: Error) => void
) => {
  try {
    const defaultOptions: RequestInit = {
      ...options,
      method: "POST",
      headers: {
        ...options.headers,
        "Content-Type": "application/json",
      },
      mode: "cors",
    };

    const response = await fetch(baseURL + url, defaultOptions);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}, url: ${url}`);
    }

    const eventStream = response.body
      ?.pipeThrough(new TextDecoderStream())
      .pipeThrough(new EventSourceParserStream());

    if (!eventStream) {
      throw new Error(`Event stream is null for url: ${url}`);
    }

    const responseReader = eventStream.getReader();

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { done, value } = await responseReader.read();

      if (done || !value || value.data.includes("[DONE]")) {
        onComplete && onComplete();
        break;
      }

      // console.log(value.data);
      onDataChunk(value.data);
    }
  } catch (error) {
    if ((error as Error).name === "AbortError") {
      console.log("Fetch operation was aborted for url: ", url);
      onComplete && onComplete();
    } else {
      console.error(error);
      onError && onError(error as Error);
    }
  }
};
