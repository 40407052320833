import { cn } from "@/lib/utils";

type CardProps = {
  children: React.ReactNode;
  className?: string;
  backgroundImage?: string;
  backgroundFill?: string;
};

export const Card = ({ children, className, backgroundImage }: CardProps) => (
  <div
    className={cn(
      "inline-flex grow flex-col items-center justify-start gap-4 rounded-3xl bg-card p-6 align-middle shadow",
      backgroundImage ? "bg-cover bg-center" : "",
      className
    )}
    style={
      backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : {}
    }
  >
    {children}
  </div>
);
