import { httpClient, isAxiosError } from "@/lib/http";
import { Model, ModelOptions } from "@/types/models";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import _ from "underscore";
import { useUser } from "./user-provider";

type ModelProviderProps = {
  children: React.ReactNode;
};

type ModelProviderState = {
  models: ModelOptions[] | null;
  loading: boolean;
  refresh: () => Promise<void>;
};

const initialState: ModelProviderState = {
  models: null,
  loading: false,
  refresh: () => Promise.resolve(),
};

const ModelProviderContext = createContext<ModelProviderState>(initialState);

export const ModelProvider = ({ children }: ModelProviderProps) => {
  const { login, getAccessToken } = useUser();
  const [models, setModels] = useState<ModelProviderState["models"] | null>(
    initialState.models
  );
  const [loading, setLoading] = useState(initialState.loading);

  const fetchModels = async () => {
    setLoading(true);
    try {
      const token = await getAccessToken();
      const response = await httpClient.get<{
        data: Model[];
      }>("/v1/models?include_disabled=true", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const dataToReturn = response.data.data
        .filter((el) => el.is_playground)
        .map((model) => ({
          avatar: model.creator_logo_url,
          label: model.name.split("/")[1] ?? model.id,
          value: `${model.id.toLocaleLowerCase()}__${model.created}`,
          ...model,
        }));

      if (dataToReturn && !_.isEqual(models, dataToReturn)) {
        setModels(dataToReturn);
      }
    } catch (e) {
      if (isAxiosError(e)) {
        if (e.response?.status === 401) {
          login();
        }
      }
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const refresh = async () => {
    await fetchModels();
  };

  useEffect(() => {
    fetchModels().catch(console.error);
  }, []);

  const value = useMemo(
    () => ({ models, loading, refresh }),
    [models, loading, refresh]
  );

  return (
    <ModelProviderContext.Provider value={value}>
      {children}
    </ModelProviderContext.Provider>
  );
};

export const useModel = () => {
  const context = useContext(ModelProviderContext);

  if (context === undefined)
    throw new Error("useModel must be used within a ModelProvider");

  return context;
};
