import { Anchor } from "@/components/base/Anchor";
import { Avatar } from "@/components/base/Avatar";
import { Button } from "@/components/base/Button";
import { Divider } from "@/components/base/Divider";
import { Popover } from "@/components/base/Popover";
import { useApp } from "@/context/app-provider";
import { useOrg } from "@/context/org-provider";
import { cn } from "@/lib/utils";
import { User } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

type SidebarProps = {
  children: React.ReactNode;
  userLoading: boolean;
  userDetailsSection: User | null;
  handleLogout: () => void;
};

export const Sidebar = ({
  children,
  userLoading = true,
  userDetailsSection,
  handleLogout,
}: SidebarProps) => {
  const navigate = useNavigate();
  const { sidebarExpanded, theme } = useApp();
  const { selectedOrganization, setSelectedOrganization, organizations } =
    useOrg();

  return (
    <aside className="fixed z-20 h-screen max-w-[212px] transition-all duration-500">
      <nav className="flex h-full flex-col justify-between border-r shadow-sm">
        <div className="h-full">
          <Button
            onClick={() => navigate("/")}
            variant="transparent"
            className="mb-4 flex h-fit w-fit items-center justify-between gap-2 p-4 pb-2"
          >
            <div
              className={cn(
                "hidden h-10 overflow-hidden transition-all delay-75",
                sidebarExpanded ? "block w-40" : "w-0"
              )}
            >
              <img
                src={`/logo/app-logo-${
                  theme === "dark" ? "dark" : "light"
                }.svg`}
                alt="Konko AI logo"
                className="h-full"
              />
            </div>
            <div
              className={cn(
                "hidden h-10 overflow-hidden transition-all delay-75",
                sidebarExpanded ? "w-0" : " block w-10"
              )}
            >
              <img
                src={`/logo/app-icon-${
                  theme === "dark" ? "dark" : "light"
                }.svg`}
                alt="Konko AI logo"
                className="h-full"
              />
            </div>
          </Button>

          <ul
            className={cn(
              "align-center flex flex-col px-3",
              sidebarExpanded && "align-baseline"
            )}
          >
            {children}
          </ul>
        </div>

        <div className="flex flex-col items-center justify-center gap-3 p-3">
          {/* <Tooltip
            content={sidebarExpanded ? "Collapse sidebar" : "Expand sidebar"}
          >
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setSidebarExpanded(!sidebarExpanded)}
              className="text-tx-foreground ml-1 self-baseline"
            >
              {sidebarExpanded ? <ChevronsLeft /> : <ChevronsRight />}
            </Button>
          </Tooltip> */}
          <Divider size={1} width={sidebarExpanded ? "w-44" : "w-10"} />
          <Popover
            popoverTriggerProps={{
              asChild: false,
              className: cn(
                "align-baseline flex flex-row items-center w-full ml-2",
                userLoading ? "animate-pulse" : "animate-none"
              ),
            }}
            popoverContentProps={{
              align: "start",
              sideOffset: 8,
            }}
            content={
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-2">
                  <h4 className="text-subtext-bold">
                    {userLoading ? "Loading..." : userDetailsSection?.name}
                  </h4>
                  <p className="text-subtext">
                    {userLoading ? "Loading..." : userDetailsSection?.email}
                  </p>
                </div>
                <Divider size={1} />
                <div className="flex flex-col gap-2">
                  <h4 className="text-subtext-bold">Organizations</h4>
                  <div className="flex flex-col gap-2">
                    {organizations?.map((org) => (
                      <Button
                        variant="ghost"
                        size="full"
                        className="justify-start truncate p-2"
                        key={org.id}
                        onClick={() => setSelectedOrganization(org)}
                        selected={selectedOrganization?.id === org.id}
                      >
                        {org.name ?? org.id}
                      </Button>
                    ))}
                  </div>
                </div>
                <Divider size={1} />
                <div className="flex flex-col gap-2">
                  <Anchor
                    href="https://www.konko.ai/terms"
                    target="_blank"
                    className="visited:text- justify-start p-2"
                  >
                    Terms and conditions
                  </Anchor>
                  <Button
                    variant="transparent"
                    size="full"
                    className="justify-start p-2"
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </div>
              </div>
            }
          >
            <>
              {userDetailsSection?.picture ? (
                <Avatar
                  src={userDetailsSection?.picture}
                  alt={`${userDetailsSection?.name}-pfp`}
                  className="h-10 w-10"
                />
              ) : (
                <Avatar className="h-10 w-10">
                  {userDetailsSection?.name ? userDetailsSection?.name[0] : "?"}
                </Avatar>
              )}
              <h4
                className={cn(
                  "text-tx-foreground font-semibold overflow-hidden whitespace-nowrap break-keep leading-4 transition-all",
                  sidebarExpanded
                    ? "ml-3 opacity-100 transition-opacity duration-100"
                    : "w-0 opacity-0"
                )}
              >
                {userLoading ? "Loading..." : userDetailsSection?.name}
              </h4>
            </>
          </Popover>
        </div>
      </nav>
    </aside>
  );
};

type SidebarItemProps = {
  icon: React.ReactNode;
  text: string;
  isActive?: boolean;
  alert?: boolean;
};

export const SidebarItem = ({
  icon,
  text,
  isActive,
  alert,
}: SidebarItemProps) => {
  const { sidebarExpanded, setSidebarExpanded } = useApp();

  return (
    <li
      aria-label="Sidebar item"
      className={cn(
        "group relative my-1 flex h-11 cursor-pointer items-center rounded-md px-2 py-2 align-baseline font-medium transition-colors",
        isActive
          ? "from-accent-200 to-accent-400 bg-accent text-accent-foreground"
          : "text-tx-foreground hover:bg-accent"
      )}
      onClick={() => {
        setSidebarExpanded(false);
      }}
    >
      {icon}

      <span
        className={cn(
          "text-links overflow-hidden whitespace-nowrap break-keep transition-all",
          sidebarExpanded
            ? "ml-3 w-32 opacity-100 transition-opacity duration-100"
            : "w-0 opacity-0"
        )}
      >
        {text}
      </span>

      {alert && (
        <div
          className={`absolute right-2 h-2 w-2 rounded bg-inactive-foreground ${
            sidebarExpanded ? "" : "top-2"
          }`}
        />
      )}

      {!sidebarExpanded && (
        <div
          className="invisible absolute left-full ml-6 w-max
          max-w-md -translate-x-3 overflow-hidden rounded-md border bg-card px-3 py-1.5 text-sm text-card-foreground opacity-20 shadow-md transition-all animate-in fade-in-0 zoom-in-95 group-hover:visible group-hover:z-[100] group-hover:translate-x-0 group-hover:opacity-100 data-[state=closed]:animate-out data-[state=closed]:fade-out-0
          data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
          aria-label="Sidebar item tooltip"
        >
          {text}
        </div>
      )}
    </li>
  );
};
