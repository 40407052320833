import { cn } from "@/lib/utils";

type TabsProps = {
  children: React.ReactNode[];
};

const Tabs = ({ children }: TabsProps) => {
  return (
    <div className="w-full">
      <div className="flex border-b border-solid border-b-neutral-300 px-2">
        {children}
      </div>
    </div>
  );
};

type TabProps = {
  isActive?: boolean;
  tabWidth?: string;
  children: React.ReactNode;
};

const Tab = ({ tabWidth = "w-32", isActive, children }: TabProps) => {
  return (
    <span
      className={cn(
        isActive
          ? "border-b-2 border-accent-foreground text-accent-foreground"
          : "text-tx-foreground rounded-md hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
        tabWidth,
        "-mb-px flex flex-row items-center justify-center py-2 font-medium"
      )}
    >
      {children}
    </span>
  );
};
export { Tab, Tabs };
