import { Button } from "@/components/base/Button";
import { useApp } from "@/context/app-provider";
import { useUser } from "@/context/user-provider";
import { cn } from "@/lib/utils";
import { Moon, Sun } from "lucide-react";
import { Outlet } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import { Topbar } from "../Topbar";

export const Layout = () => {
  const { user, auth0Loading, logout } = useUser();
  const {
    topbarName,
    theme,
    setTheme,
    topbarBottomBorder,
    sidebarExpanded,
    topbarCustomActions,
  } = useApp();
  // const location = useLocation();
  // const currentPath = location.pathname;

  return (
    <div className="flex flex-row">
      <Sidebar
        userLoading={auth0Loading}
        userDetailsSection={user}
        handleLogout={logout}
      >
        <></>
        {/* <NavLink to="/">
          {({ isActive }) => (
            <SidebarItem
              icon={<House className="h-7 w-8" />}
              text="Home"
              isActive={isActive}
            />
          )}
        </NavLink>
        <NavLink to="/playground/chat">
          {({ isActive }) => (
            <SidebarItem
              icon={<GameController className="h-7 w-8" />}
              text="Playground"
              isActive={isActive || currentPath.includes("/playground")}
            />
          )}
        </NavLink>
        <NavLink to="/custom-models">
          {({ isActive }) => (
            <SidebarItem
              icon={<Dna className="h-7 w-8" />}
              text="Custom models"
              isActive={isActive}
            />
          )}
        </NavLink>
        <NavLink to="/production-api">
          {({ isActive }) => (
            <SidebarItem
              icon={<RocketLaunch className="h-7 w-8" />}
              text="Production API"
              isActive={isActive}
            />
          )}
        </NavLink> */}
        {/* <NavLink to="/documentation">
          {({ isActive }) => (
            <SidebarItem
              icon={<BookOpenText className="h-7 w-8" />}
              text="Documentation"
              isActive={isActive}
            />
          )}
        </NavLink> */}
        {/* <a
          href="https://docs.konko.ai/docs/getting-started"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <SidebarItem
            icon={<BookOpenText className="h-7 w-8" />}
            text="Documentation"
          />
        </a>
        <NavLink to="/settings/profile">
          {({ isActive }) => (
            <SidebarItem
              icon={<GearSix className="h-7 w-8" />}
              text="Settings"
              isActive={isActive || currentPath.includes("/settings")}
            />
          )}
        </NavLink> */}
      </Sidebar>
      <div
        className={cn(
          "flex flex-1 flex-col",
          sidebarExpanded ? "ml-[212px]" : "ml-[73px]"
        )}
      >
        <Topbar borderBottom={topbarBottomBorder}>
          <div className="flex flex-1 items-center justify-between">
            <h1 className="text-page-title">{topbarName}</h1>
            <div className="flex items-center gap-10">
              {topbarCustomActions}
              <Button
                variant="outline"
                size="icon"
                onClick={() => {
                  setTheme(theme === "light" ? "dark" : "light");
                }}
                className="text-tx"
              >
                {theme === "light" ? <Moon /> : <Sun />}
              </Button>
            </div>
          </div>
        </Topbar>
        <main className="min-h-[calc(100vh_-_79px)] flex-1">{<Outlet />}</main>
      </div>
    </div>
  );
};
