import { cn } from "@/lib/utils";

type DividerProps = {
  width?: string;
  size?: 1 | 2 | 4;
  color?: "primary" | "destructive" | "inactive";
  margin?: string;
};

export const Divider = ({
  width = "w-full",
  size = 1,
  color = "inactive",
  margin = "my-0",
}: DividerProps) => (
  <hr
    className={cn(
      "rounded-md border-t border-solid",
      `border-${size}`,
      `border-${color}`,
      width,
      margin
    )}
  />
);
