import { useLayoutEffect, useState } from "react";

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    const userAgent = window.navigator.userAgent;
    const screenWidth = window.innerWidth;

    const isMobileUserAgent = /iPhone|iPad|iPod|Android/i.test(userAgent);
    const isMobileScreenWidth = screenWidth < 576;

    setIsMobile(isMobileUserAgent || isMobileScreenWidth);

    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const isMobileScreenWidth = screenWidth < 576;
      setIsMobile(isMobileUserAgent || isMobileScreenWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile;
};
