import { cn } from "@/lib/utils";
import { ArrowSquareIn } from "@phosphor-icons/react";

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  children: React.ReactNode;
  className?: string;
};

export const Anchor = ({
  children,
  className,
  target,
  rel,
  ...props
}: AnchorProps) => (
  <a
    className={cn(
      "inline-flex cursor-pointer items-center gap-1 text-sm text-accent-foreground underline-offset-4 visited:text-purple-600 hover:underline",
      className
    )}
    target={target ?? "_blank"}
    rel={rel ?? "noopener noreferrer"}
    {...props}
  >
    {children}
    {target === "_blank" && <ArrowSquareIn />}
  </a>
);
