import { Button } from "@/components/base/Button";
import { Card } from "@/components/base/Card";
import { SectionHeader } from "@/components/base/SectionHeader";
import { useOrg } from "@/context/org-provider";
import { useUser } from "@/context/user-provider";
import { httpClient, isAxiosError } from "@/lib/http";
import { cn } from "@/lib/utils";
import { InviteDetails } from "@/types/users";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export const AcceptInvite = () => {
  const { getAccessToken } = useUser();
  const { organizations, refresh, setSelectedOrganization } = useOrg();
  const { inviteId } = useParams<{ inviteId: string }>();
  const [loading, setLoading] = useState(false);
  const [inviteData, setInviteData] = useState<InviteDetails | null>(null);

  const getInvites = async () => {
    setLoading(true);
    try {
      const token = await getAccessToken();
      const response = await httpClient.get<InviteDetails>(
        `/v1/invites/${inviteId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setInviteData(response.data);
    } catch (e) {
      if (isAxiosError(e)) {
        if (e.response?.status === 401) {
          toast.error("You must be logged in to accept an invite");
        }
      }
      throw e;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvites().catch(console.error);
  }, []);

  const acceptInvite = async () => {
    try {
      const token = await getAccessToken();
      await httpClient.post<InviteDetails>(
        `/v1/invites/${inviteId}/accept`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await refresh();

      if (!organizations) {
        throw new Error("No organizations found");
      }

      if (inviteData?.organization != null) {
        setSelectedOrganization(inviteData?.organization);
      }

      toast.success(`You have been added to ${inviteData?.organization.name}`);
    } catch (e) {
      if (isAxiosError(e)) {
        if (e.response?.status === 401) {
          toast.error("This invite isn't valid for you.");
        }
      }
      throw e;
    }
  };

  return (
    <div
      className={cn(
        "flex h-[calc(100vh_-_79px)] w-full flex-col items-center justify-center"
      )}
    >
      <Card className="h-fit grow-0">
        <SectionHeader>Accept invite</SectionHeader>
        <div
          className={cn(
            "flex w-[496px] flex-col gap-6",
            loading ? "animate-pulse" : "animate-none"
          )}
        >
          <div className="flex flex-col gap-4">
            {loading ? (
              <div className="text-headline">Loading invite details...</div>
            ) : (
              <div className="text-headline">
                {inviteData?.user.full_name} invited you to be a member of the
                organization{" "}
                <span className="font-bold">
                  {inviteData?.organization.name}
                </span>{" "}
                ({inviteData?.organization.id}) as {inviteData?.invite.role}.
              </div>
            )}
            <div className="flex gap-2">
              <Button
                variant="success"
                disabled={loading}
                onClick={() => void acceptInvite()}
              >
                Accept invite
              </Button>
            </div>

            <div>
              If you don&apos;t recognize this, you may safely ignore it.
            </div>

            {!loading && (
              <div className="text-subtitle !text-xs">
                The identifier for this organization is{" "}
                {inviteData?.organization.id}
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};
