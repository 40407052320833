import { handleAxiosError, httpClient } from "@/lib/http";
import { UserExtra } from "@/types/users";

export const getCurrentUser = async (
  accessToken: string,
  login: () => void
) => {
  try {
    const response = await httpClient.get<{ current_user: UserExtra }>(
      `/v1/current-user`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data.current_user;
  } catch (e) {
    handleAxiosError(e, login);
  }
};
