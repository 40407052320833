import { Layout } from "@/components/module/Layout";
import { wrapCreateBrowserRouter } from "@sentry/react";
import { Suspense, lazy } from "react";
import { RouterProvider, createBrowserRouter, Navigate } from "react-router-dom";
import { Loading } from "../components/module/Loading";
import { AcceptInvite } from "./AcceptInvite";
import { NotFound } from "./NotFound";
import { SettingsLayout } from "./Settings";

// const LazyCompletion = lazy(async () => {
//   const { Completion } = await import("./Playground/Completion");
//   return {
//     default: Completion,
//   };
// });

// const LazyChat = lazy(async () => {
//   const { Chat } = await import("./Playground/Chat");
//   return {
//     default: Chat,
//   };
// });

const LazySettingsProfile = lazy(async () => {
  const { ProfilePage } = await import("./Settings/Profile");
  return {
    default: ProfilePage,
  };
});

const LazySettingsAPIKeys = lazy(async () => {
  const { ApiKeysPage } = await import("./Settings/ApiKeys");
  return {
    default: ApiKeysPage,
  };
});

// const LazySettingsBilling = lazy(async () => {
//   const { BillingPage } = await import("./Settings/Billing");
//   return {
//     default: BillingPage,
//   };
// });

const LazySettingsOrganization = lazy(async () => {
  const { OrganizationPage } = await import("./Settings/Organization");
  return {
    default: OrganizationPage,
  };
});

const LazySettingsTeam = lazy(async () => {
  const { TeamPage } = await import("./Settings/Team");
  return {
    default: TeamPage,
  };
});

const LazySettingsPrivacy = lazy(async () => {
  const { PrivacyPage } = await import("./Settings/Privacy");
  return {
    default: PrivacyPage,
  };
});

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const appRouter = sentryCreateBrowserRouter([
  {
    element: <Layout />,
    hasErrorBoundary: true,
    children: [
      {
        path: "/accept-invite/:inviteId",
        element: <AcceptInvite />,
      },
      // {
      //   path: "/playground",
      //   element: <PlaygroundLayout />,
      //   children: [
      //     {
      //       index: true,
      //       path: "/playground/chat",
      //       element: (
      //         <Suspense fallback={<PlaygroundLoader />}>
      //           <ChatSettingsProvider>
      //             <LazyChat />
      //           </ChatSettingsProvider>
      //         </Suspense>
      //       ),
      //     },
      //     {
      //       path: "/playground/completion",
      //       element: (
      //         <Suspense fallback={<PlaygroundLoader />}>
      //           <CompletionSettingsProvider>
      //             <LazyCompletion />
      //           </CompletionSettingsProvider>
      //         </Suspense>
      //       ),
      //     },
      //   ],
      // },
      // {
      //   path: "/custom-models",
      //   element: <CustomModels />,
      // },
      // {
      //   path: "/production-api",
      //   element: <ProductionAPI />,
      // },
      {
        path: "/settings",
        element: <SettingsLayout />,
        children: [
          {
            index: true,
            path: "profile",
            element: (
              <Suspense fallback={<Loading />}>
                <LazySettingsProfile />
              </Suspense>
            ),
          },
          {
            path: "api-keys",
            element: (
              <Suspense fallback={<Loading />}>
                <LazySettingsAPIKeys />
              </Suspense>
            ),
          },
          // {
          //   path: "/settings/billing",
          //   element: (
          //     <Suspense fallback={<SettingsLoader />}>
          //       <LazySettingsBilling />
          //     </Suspense>
          //   ),
          // },
          {
            path: "organization",
            element: (
              <Suspense fallback={<Loading />}>
                <LazySettingsOrganization />
              </Suspense>
            ),
          },
          {
            path: "team",
            element: (
              <Suspense fallback={<Loading />}>
                <LazySettingsTeam />
              </Suspense>
            ),
          },
          {
            path: "privacy",
            element: (
              <Suspense fallback={<Loading />}>
                <LazySettingsPrivacy />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "/",
        element: <Navigate to="/settings/profile" />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

export const AppRouter = () => {
  return (
    <Suspense fallback={<Loading />}>
      <RouterProvider router={appRouter} />
    </Suspense>
  );
};
