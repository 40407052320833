import { AppProvider } from "@/context/app-provider";
import { AppRouter } from "@/views/AppRouter";
import { Auth0Provider } from "@auth0/auth0-react";
import { IconContext } from "@phosphor-icons/react";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ErrorBoundary } from "./components/module/ErrorBoundary";
import { MobileView } from "./components/module/MobileView";
import { OrgProvider } from "./context/org-provider";
import { UserProvider } from "./context/user-provider";
import { useIsMobile } from "./hooks/useIsMobile";

const auth0Domain = import.meta.env.VITE_PUBLIC_AUTH0_DOMAIN;
const auth0ClientId = import.meta.env.VITE_PUBLIC_AUTH0_CLIENT_ID;
const auth0Audience = import.meta.env.VITE_PUBLIC_AUTH0_AUDIENCE;

export const App = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <AppProvider>
        <MobileView />
      </AppProvider>
    );
  }

  return (
    <ErrorBoundary>
      <Auth0Provider
        domain={auth0Domain}
        clientId={auth0ClientId}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: auth0Audience,
        }}
        useRefreshTokens
        cacheLocation="localstorage"
      >
        <UserProvider>
          <AppProvider>
            <OrgProvider>
              <IconContext.Provider
                value={{
                  size: 24,
                  mirrored: false,
                }}
              >
                <AppRouter />
              </IconContext.Provider>
            </OrgProvider>
            <ToastContainer
              closeButton={false}
              draggable={false}
              hideProgressBar
              pauseOnFocusLoss={false}
              position="top-center"
              theme="colored"
              autoClose={3000}
              closeOnClick
              transition={Zoom}
              icon={false}
              limit={3}
            />
          </AppProvider>
        </UserProvider>
      </Auth0Provider>
    </ErrorBoundary>
  );
};
