import { Anchor } from "@/components/base/Anchor";
import { useApp } from "@/context/app-provider";

export const MobileView = () => {
  const { theme } = useApp();

  return (
    <main className="flex h-screen">
      <div className="flex h-full w-full flex-col items-center justify-center gap-5 p-3 align-middle">
        <img
          src={`/logo/app-icon-${theme === "dark" ? "dark" : "light"}.svg`}
          alt="Loading..."
          height="70px"
          width="70px"
        />
        <span className="text-headline text-tx-secondary text-center">
          We&apos;re still building the mobile version of our web application.
          <br />
          Please check us out on desktop.
        </span>

        <span className="text-body text-tx-secondary text-center">
          Thank you for your patience.
        </span>

        <Anchor
          href="https://www.konko.ai/"
          target="_blank"
          className="visited:text-"
        >
          Take me to konko.ai website
        </Anchor>
      </div>
    </main>
  );
};
